import React from "react";
import logo from '../assets/athenLogo.svg';

const Header = () => {

 return (
        <header className="App-header">
            <img src={logo} className="App-logo" alt="" />
        </header>
    )
};

export default Header;
