import React from "react";
import Header from './components/Header';
import Video from './components/Video';
import OpeningHours from './components/OpeningHours';
import OrderMenue from './components/OrderMenue';
import Footer from './components/Footer';
import './App.css';
import Hint from './components/Hint';

function App() {
  return (
    <div className="App">
      <Header />
      <Hint />
      <Video />
      <OpeningHours />
      <OrderMenue />
      <Footer/>
    </div>
  );
}

export default App;
