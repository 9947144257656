import React from "react";
import moment from 'moment';

const end = '2024-01-05';
const headline = 'Wir haben vom 23.12.23 bis 04.01.24 geschlossen.';
const text = 'Wir wünschen ein Frohes Fest und einen guten Rutsch ins neue Jahr!';

const Hint = () => {
    if (moment().format('YYYY-MM-DD') < end) {
        return (
            <div className="hint">
                <div className="inner">
                    <h4>{headline}</h4>
                    <p>{text}</p>
                    <p>Euer Team Athen</p>
                </div>
            </div>
        )
    }

 return <></>
};

export default Hint;
